@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans TC", sans-serif,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

/* antd message 變更 */
.ant-message {
  font-size:23px !important;
  color: #595959 !important;
}

/* 滾動條樣式 */
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 10px;

}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
  border-radius: 10px;
}

/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: rgba(255, 255, 255, 0.01) !important;
} */

.my-icon-style {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -7px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 1;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.page-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.clickRowStyl {
  background-color: rgba(246, 252, 74, 0.5);
}

.WocWo-select-bg .ant-select-selector {
  border-radius: 10px;
}

.WocWo-select-bg .ant-select-selection-item {
  color: #ffffff !important;
}
.WocWo-select-bg .ant-select-arrow {
  color: #ffffff !important;
}
.ant-pagination-total-text {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: left;
}
.ant-table {
  border: 0.1px solid #dbdbdb;
}

.wocwo-productivity .ant-progress-text {
  display: none;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav .productivity {
  margin: 0px 0px 5px 0px;
}

/* antd tabs  */
.ant-tabs-tab{
  font-size: 24px !important; 
  padding:8px 16px !important;
}
.ant-table-pagination-right {
  margin: 8px 0 !important;
}
/* tab 原先設定是隨子容器大小決定 現在跟隨父元素 */
.ant-tabs-tabpane .ant-tabs-tabpane-active,
.ant-tabs-content,
.ant-tabs-tabpane > .ant-row
{
  height: 100% !important;
}
/* ----------------------- */

/* table min col min width */
.row-min-width {
  width: 100%;
  min-width: 90px;
  max-width: 280px;
}
.row-last-col-width{
  width: 100%;
  min-width: 90px;
}
.col-white-space{
  white-space: nowrap; /*//强制文本在一行内输出 */
  overflow: hidden; /*隐藏溢出部分*/
  text-overflow: ellipsis; /*//對溢出部分加上...*/
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.wos-tk-table-col{
  font-size: 16px;
  padding: 4px;
}


/* ------------------------- */

/* all input & table col key font-size */
input,
.ant-select-selection-item,
td,
th {
  font-size: 21px;
}


/* ---------------------------------- */
.pdf_center {
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  height: -webkit-max-content;
  height: max-content;
  max-height: 100%;
}
/* 控制新增多單的 -號位置 */
#multipleWonForm .dynamic-delete-button-box {
  position: relative;
  height: 100%;
  width: 100%;
}

.woc-productivity .ant-progress-text{
  display: none !important; 
}
.dynamic-delete-button {
  position: absolute;
  top: 30px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}


.Wraper {
  width: 800px;
  height: 100%;
}
.flex-col-c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.flex_c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.border-t {
  border-top: 1px solid #000
}
.border-r {
  border-right: 1px solid #000
}
.border-b {
  border-bottom: 1px solid #000
}
.border-l {
  border-left: 1px solid #000
}
.border-2-t {
  border-top: 2px solid #000;
}
.border-2-r {
  border-right: 2px solid #000;
}
.border-2-b {
  border-bottom: 2px solid #000;
}
.border-2-l {
  border-left: 2px solid #000;
}


.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.Header {
  font-size: 20px;
  color: #000;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Header .Info-text {
  font-size: 24px;
}

.H3 {
  font-size: 23px;
}

.HeaderInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.box {
  width: 100px;
  height: 100px;
  background-color: #000;
}

.QRCode {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.woN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.woN .title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.flex-col-c{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.flex-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: left;
          justify-content: left;
}

.woN .value {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16.66%;
}

.w-20 {
  width: 20%;
}

.w-22 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width :30%;
}

.w-33_5 {
  width: 33.5%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width :40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.h-30 {
  height: 30px;
}

.h-50 {
  height: 40px;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100px;
}

.h-120 {
  height: 120px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-70p {
  padding-left: 70%;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30p {
  margin-right: 30%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.title {
  font-size: 17px;
  /* font-weight: 900; */
  /* padding:10px; */

}

.title1 {
  font-size: 17px;
  /* font-weight: 900; */
  color:#000
}

.PN {
  display: -webkit-flex;
  display: flex;

}

.PN .title {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.PN .value {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.pdtNM {
  display: -webkit-flex;
  display: flex;
}

.pdtNM .title {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.pdtNM .value {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.ws {
  display: -webkit-flex;
  display: flex;
  /* align-items: center; */
  /* background-color: pink; */
}

.ws .title {
  width: 50%;
  text-align: center;
}

.ws .value {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.wl {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.wl .title {
  width: 50%;
  text-align: center;
}

.wl .value {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.flex-r {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.flex-c {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.secInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.left {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.right {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.List {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.List > div {
  width: 10%;
}

.MaterialText {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.MaterialRight {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.flex-g {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.ml-1x {
  margin-left: -1px
}

.ml-1 {
  margin-left: 1px
}

.Log {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* align-items: center; */
}

.LogData {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
/* 時間軸亮 */
.prefix__b {
  /* fill: #55eb31 */
  fill: #55EB31
}

/* 時間軸燈亮 */
.prefix__h {
  fill: #267068
}

.prefix__dark {
  fill:#C4C4C4
}

.prefix__pass {
  fill:#699f5b
}

.prefix__c {
  fill: #267068
}

.prefix__c {
  font-size: 17px
}

.prefix__c,
.prefix__d,
.prefix__l {
  font-family: MicrosoftJhengHeiRegular, Microsoft JhengHei
}

.prefix__d,
.prefix__l,
.prefix__o {
  fill: #bebebe
}

.prefix__d {
  font-size: 16px
}

.prefix__e {
  fill: #c4c4c4
}

.prefix__g {
  fill: #fff
}

.prefix__r {
  fill: #707070
}

.prefix__l {
  font-size: 13px
}

.prefix__r {
  fill-rule: evenodd
}


.mc .flex-col-c {
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.mc .Wraper {
  width: 800px;
  height: 100%;
}

.mc .felx-wrap {
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap
}

.mc .flex_c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.mc .border-2-t {
  border-top: 2px solid #000;
}
.mc .border-2-r {
  border-right: 2px solid #000;
}
.mc .border-2-b {
  border-bottom: 2px solid #000;
}
.mc .border-2-l {
  border-left: 2px solid #000;
}

.mc .border-t {
  border-top: 1px solid #000;
}
.mc .border-r {
  border-right: 1px solid #000;
}
.mc .border-b {
  border-bottom: 1px solid #000;
}
.mc .border-l {
  border-left: 1px solid #000;
}

.mc .font-14 {
  font-size: 14px;
}

.mc .font-17 {
  font-size: 17px;
}

.mc .font-24 {
  font-size: 24px;
}

.mc .font-30 {
  font-size: 30px;
}

.mc .Header {
  font-size: 20px;
  color: #000;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.mc .H3 {
  font-size: 23px;
}

.mc .HeaderInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.mc .box {
  width: 100px;
  height: 100px;
  background-color: #000;
}

.mc .QRCode {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.mc .woN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.mc .woN .mc .title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.mc .flex-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: left;
          justify-content: left;
}

.mc .flex-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: right;
          justify-content: right;
}

.mc .woN .mc .value {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .w-10 {
  width: 10%;
}

.mc .w-15 {
  width: 15%;
}

.mc .w-16_3 {
  width: 16.33333%;
}

.mc .w-16_4 {
  width: 16.4%;
}

.mc .w-16_75 {
  width: 16.75%;
}

.mc .w-17 {
  width: 17%;
}

.mc .w-17_15 {
  width: 17.15%;
}

.mc .w-20 {
  width: 20%;
}

.mc .w-25 {
  width: 25%;
}

.mc .w-26 {
  width: 26%;
}

.mc .w-30 {
  width: 30%;
}

.mc .w-33 {
  width: 33.33333%;
}

.mc .w-34_5 {
  width: 34.5%;
}

.mc .w-35 {
  width: 35%;
}

.mc .w-36_3 {
  width: 36.2%;
}

.mc .w-40 {
  width: 40%;
}


.mc .w-50 {
  width: 50%;
}

.mc .w-56 {
  width: 56%;
}

.mc .w-60 {
  width: 60%;
}

.mc .w-80 {
  width: 80%;
}


.mc .w-100 {
  width: 100%;
}

.mc .h-30 {
  height: 30px;
}

.mc .h-50 {
  height: 50px;
}

.mc .h-40 {
  height: 40px;
}
.mc .h-49 {
  height: 49px;
}

.mc .h-60 {
  height: 60px;
}

.mc .h-80 {
  height: 80px;
}

.mc .h-100 {
  height: 100px;
}

.mc .h-110 {
  height: 110px;
}

.mc .h-120 {
  height: 120px;
}

.mc .h-150 {
  height: 150px;
}

.mc .h-160 {
  height: 160px;
}
.mc .h-170 {
  height: 170px;
}

.mc .pt-5 {
  padding-top: 5px;
}

.mc .pr-5 {
  padding-right: 5px;
}

.mc .pb-5 {
  padding-bottom: 5px;
}

.mc .pl-5 {
  padding-left: 5px;
}

.mc .pl-10 {
  padding-left: 10px;
}

.mc .pt-10 {
  padding-top: 10px;
}

.mc .pl-15 {
  padding-left: 15px;
}

.mc .pl-30 {
  padding-left: 30px;
}

.mc .pl-40 {
  padding-left: 40px;
}

.mc .pr-10 {
  padding-right: 10px;
}

.mc .pr-15 {
  padding-right: 10px;
}


.mc .mr-10 {
  margin-right: 10px;
}

.mc .ml-5 {
  margin-left: 5px;
}

.mc .ml-10 {
  margin-left: 10px;
}

.mc .ml-30 {
  margin-left: 30px;
}

.mc .mr-30p {
  margin-right: 30%;
}

.mc .mt-10 {
  margin-top: 10px;
}

.mc .mt-20 {
  margin-top: 20px;
}

.mc .mt-30 {
  margin-top: 30px;
}

.mc .title {
  font-size: 17px;
  /* font-weight: 900; */
  /* padding:10px; */
}

.mc .break {
  word-break: break-all;
}

.mc .title1 {
  font-size: 17px;
  /* font-weight: 900; */
  color: #000;
}

.mc .PN {
  display: -webkit-flex;
  display: flex;
}

.mc .PN .mc .title {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .PN .mc .value {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .pdtNM {
  display: -webkit-flex;
  display: flex;
}

.mc .pdtNM .mc .title {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .pdtNM .mc .value {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .ws {
  display: -webkit-flex;
  display: flex;
  /* align-items: center; */
  /* background-color: pink; */
}

.mc .ws .mc .title {
  width: 50%;
  text-align: center;
}

.mc .ws .mc .value {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .wl {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.mc .wl .mc .title {
  width: 50%;
  text-align: center;
}

.mc .wl .mc .value {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mc .flex-r {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.mc .flex-c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.mc .secInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.mc .left {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.mc .right {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.mc .List {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.mc .List > div {
  width: 10%;
}

.mc .MaterialText {
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.mc .MaterialRight {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.mc .flex-g {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.mc .ml-1x {
  margin-left: -1px;
}

.mc .ml-1 {
  margin-left: 1px;
}

.mc .Log {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* align-items: center; */
}

.mc .LogData {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.Wraper {
  width: 800px;
  height: 100%;
}
.flex-col-c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.flex_c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.border-t {
  border-top: 1px solid #000
}
.border-r {
  border-right: 1px solid #000
}
.border-b {
  border-bottom: 1px solid #000
}
.border-l {
  border-left: 1px solid #000
}

.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.Header {
  font-size: 20px;
  color: #000;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Header .Header-Info {
  font-size: 24px;
}

.H3 {
  font-size: 23px;
}

.HeaderInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.box {
  width: 100px;
  height: 100px;
  background-color: #000;
}

.QRCode {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.woN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.woN .title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.flex-col-c{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.flex-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: left;
          justify-content: left;
}

.woN .value {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16.66%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width :30%;
}

.w-33_5 {
  width: 33.5%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width :40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.h-30 {
  height: 30px;
}

.h-50 {
  height: 40px;
}

.h-75 {
  height: 60px;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100px;
}

.h-120 {
  height: 120px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-70p {
  padding-left: 70%;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30p {
  margin-right: 30%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.title {
  font-size: 17px;
  /* font-weight: 900; */
  /* padding:10px; */

}

.title1 {
  font-size: 17px;
  /* font-weight: 900; */
  color:#000
}

.PN {
  display: -webkit-flex;
  display: flex;

}

.PN .title {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.PN .value {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.pdtNM {
  display: -webkit-flex;
  display: flex;
}

.pdtNM .title {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.pdtNM .value {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.ws {
  display: -webkit-flex;
  display: flex;
  /* align-items: center; */
  /* background-color: pink; */
}

.ws .title {
  width: 50%;
  text-align: center;
}

.ws .value {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.wl {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.wl .title {
  width: 50%;
  text-align: center;
}

.wl .value {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.flex-r {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.flex-c {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.secInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.left {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.right {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.List {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.List > div {
  width: 10%;
}

.MaterialText {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.MaterialRight {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.flex-g {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.ml-1x {
  margin-left: -1px
}

.ml-1 {
  margin-left: 1px
}

.Log {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* align-items: center; */
}

.LogData {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
