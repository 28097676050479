
.mc .flex-col-c {
  display: flex;
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.mc .Wraper {
  width: 800px;
  height: 100%;
}

.mc .felx-wrap {
  flex-wrap:wrap
}

.mc .flex_c {
  display: flex;
  flex-direction: column;
}

.mc .border-2-t {
  border-top: 2px solid #000;
}
.mc .border-2-r {
  border-right: 2px solid #000;
}
.mc .border-2-b {
  border-bottom: 2px solid #000;
}
.mc .border-2-l {
  border-left: 2px solid #000;
}

.mc .border-t {
  border-top: 1px solid #000;
}
.mc .border-r {
  border-right: 1px solid #000;
}
.mc .border-b {
  border-bottom: 1px solid #000;
}
.mc .border-l {
  border-left: 1px solid #000;
}

.mc .font-14 {
  font-size: 14px;
}

.mc .font-17 {
  font-size: 17px;
}

.mc .font-24 {
  font-size: 24px;
}

.mc .font-30 {
  font-size: 30px;
}

.mc .Header {
  font-size: 20px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mc .H3 {
  font-size: 23px;
}

.mc .HeaderInfo {
  display: flex;
  flex-direction: row;
}

.mc .box {
  width: 100px;
  height: 100px;
  background-color: #000;
}

.mc .QRCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.mc .woN {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mc .woN .mc .title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mc .flex-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.mc .flex-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.mc .woN .mc .value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .w-10 {
  width: 10%;
}

.mc .w-15 {
  width: 15%;
}

.mc .w-16_3 {
  width: 16.33333%;
}

.mc .w-16_4 {
  width: 16.4%;
}

.mc .w-16_75 {
  width: 16.75%;
}

.mc .w-17 {
  width: 17%;
}

.mc .w-17_15 {
  width: 17.15%;
}

.mc .w-20 {
  width: 20%;
}

.mc .w-25 {
  width: 25%;
}

.mc .w-26 {
  width: 26%;
}

.mc .w-30 {
  width: 30%;
}

.mc .w-33 {
  width: 33.33333%;
}

.mc .w-34_5 {
  width: 34.5%;
}

.mc .w-35 {
  width: 35%;
}

.mc .w-36_3 {
  width: 36.2%;
}

.mc .w-40 {
  width: 40%;
}


.mc .w-50 {
  width: 50%;
}

.mc .w-56 {
  width: 56%;
}

.mc .w-60 {
  width: 60%;
}

.mc .w-80 {
  width: 80%;
}


.mc .w-100 {
  width: 100%;
}

.mc .h-30 {
  height: 30px;
}

.mc .h-50 {
  height: 50px;
}

.mc .h-40 {
  height: 40px;
}
.mc .h-49 {
  height: 49px;
}

.mc .h-60 {
  height: 60px;
}

.mc .h-80 {
  height: 80px;
}

.mc .h-100 {
  height: 100px;
}

.mc .h-110 {
  height: 110px;
}

.mc .h-120 {
  height: 120px;
}

.mc .h-150 {
  height: 150px;
}

.mc .h-160 {
  height: 160px;
}
.mc .h-170 {
  height: 170px;
}

.mc .pt-5 {
  padding-top: 5px;
}

.mc .pr-5 {
  padding-right: 5px;
}

.mc .pb-5 {
  padding-bottom: 5px;
}

.mc .pl-5 {
  padding-left: 5px;
}

.mc .pl-10 {
  padding-left: 10px;
}

.mc .pt-10 {
  padding-top: 10px;
}

.mc .pl-15 {
  padding-left: 15px;
}

.mc .pl-30 {
  padding-left: 30px;
}

.mc .pl-40 {
  padding-left: 40px;
}

.mc .pr-10 {
  padding-right: 10px;
}

.mc .pr-15 {
  padding-right: 10px;
}


.mc .mr-10 {
  margin-right: 10px;
}

.mc .ml-5 {
  margin-left: 5px;
}

.mc .ml-10 {
  margin-left: 10px;
}

.mc .ml-30 {
  margin-left: 30px;
}

.mc .mr-30p {
  margin-right: 30%;
}

.mc .mt-10 {
  margin-top: 10px;
}

.mc .mt-20 {
  margin-top: 20px;
}

.mc .mt-30 {
  margin-top: 30px;
}

.mc .title {
  font-size: 17px;
  /* font-weight: 900; */
  /* padding:10px; */
}

.mc .break {
  word-break: break-all;
}

.mc .title1 {
  font-size: 17px;
  /* font-weight: 900; */
  color: #000;
}

.mc .PN {
  display: flex;
}

.mc .PN .mc .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .PN .mc .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .pdtNM {
  display: flex;
}

.mc .pdtNM .mc .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .pdtNM .mc .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .ws {
  display: flex;
  /* align-items: center; */
  /* background-color: pink; */
}

.mc .ws .mc .title {
  width: 50%;
  text-align: center;
}

.mc .ws .mc .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .wl {
  display: flex;
  align-items: center;
}

.mc .wl .mc .title {
  width: 50%;
  text-align: center;
}

.mc .wl .mc .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc .flex-r {
  display: flex;
  flex-direction: row;
}

.mc .flex-c {
  display: flex;
  flex-direction: column;
}

.mc .secInfo {
  display: flex;
  flex-direction: row;
}

.mc .left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.mc .right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.mc .List {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.mc .List > div {
  width: 10%;
}

.mc .MaterialText {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mc .MaterialRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mc .flex-g {
  flex-grow: 1;
}

.mc .ml-1x {
  margin-left: -1px;
}

.mc .ml-1 {
  margin-left: 1px;
}

.mc .Log {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.mc .LogData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
